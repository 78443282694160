import axios from '@/libs/axios'

const roleActions = {
    fetchRolesForSelect() {
        return new Promise((resolve, reject) => {
            axios.get('/roles/list')
                .then(response => {
                    const list = []

                    if (response.data.entities) {
                        for (const key in response.data.entities) {
                            list.push({
                                value: parseInt(key),
                                title: response.data.entities[key],
                            })
                        }
                    }

                    resolve(list)
                }).catch(error => {
                reject(error)
            })
        })
    }, 
    fetchRoles({ commit, dispatch }, searchParams) {
        // todo fontos get hívásnál hogy a params object key alatt menjenek a keresőparaméterek
        return axios.get('/roles', { params: searchParams })
    },
    fetchRoleList({ commit, dispatch }, searchParams) {
        return axios.get('/roles/list', { params: searchParams })
    },
    fetchRolesForTableFilter({dispatch}) {
        return new Promise((resolve, reject) => {
            dispatch('fetchRoleList')
              .then(response => {
                  const list = []

                  if (response.data.entities) {
                      for (const key in response.data.entities) {
                          list.push({
                              value: parseInt(key),
                              text: response.data.entities[key],
                          })
                      }
                  }

                  resolve(list)
              }).catch(error => {
                reject(error)
            })
        })
    },
    fetchRole({ commit, dispatch }, id) {
        return axios.get(`/roles/${id}`)
    },
    storeRole({ commit, dispatch }, data) {
        return axios.post('/roles', data)
    },
    updateRole({ commit, dispatch }, data) {
        return axios.put(`/roles/${data.id}`, data)
    },
    deleteRole({ commit, dispatch }, id) {
        return axios.delete(`/roles/${id}`)
    },
}

export default roleActions
