import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'
import permission from '@/libs/permission'
import i18n from '@/libs/i18n'
import { localize } from 'vee-validate'
import helpers from '@/libs/helper'
import formatters from '@/libs/formatter'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

require('./bootstrap')

Vue.use({
  install() {
    Vue.acl = permission
    Vue.prototype.$acl = permission
    Vue.helpers = helpers
    Vue.prototype.$helpers = helpers
    Vue.formatters = formatters
    Vue.prototype.$formatters = formatters
  },
})

new Vue({
  router,
  store,
  i18n,
  mounted() {
    this.$store.dispatch('initCurrentUser')
    // change validation lang
    localize(this.$i18n.locale)
  },
  render: h => h(App),
}).$mount('#app')

window.Vue = Vue
