export default [
  {
    path: '/reservation-dashboard',
    name: 'reservation-dashboard',
    component: () => import('@/views/pages/reservation/Dashboard.vue'),
    meta: {
      pageTitle: 'breadcrumb.reservations',
      resource: 'reservation',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.dashboard',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reservation/statistics',
    name: 'reservations-statistics',
    component: () => import('@/views/pages/reservation/Statistics.vue'),
    meta: {
      pageTitle: 'breadcrumb.reservations',
      resource: 'reservation',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.statistics',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reservations',
    name: 'reservations',
    component: () => import('@/views/pages/reservation/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.reservations',
      resource: 'reservation',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reservations/add',
    name: 'add-reservation',
    component: () => import('@/views/pages/reservation/Add.vue'),
    meta: {
      pageTitle: 'breadcrumb.reservations',
      resource: 'reservation',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reservations/modify/:id',
    name: 'modify-reservation',
    component: () => import('@/views/pages/reservation/Modify.vue'),
    meta: {
      pageTitle: 'breadcrumb.reservations',
      resource: 'reservation',
      action: 'edit',
      breadcrumb: [
        {
          text: 'breadcrumb.modify',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reservations/show/:id',
    name: 'show-reservation',
    component: () => import('@/views/pages/reservation/Show.vue'),
    meta: {
      pageTitle: 'breadcrumb.reservations',
      resource: 'reservation',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.show',
          active: true,
        },
      ],
    },
  },
]
