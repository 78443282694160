import moment from 'moment'

export default {
  getCurrentDate(format = 'YYYY-MM-DD') {
    return moment(new Date()).format(format)
  },
  getDate(date){
    return moment(date)
  },
  getTimezoneOffset(date){
    return moment.parseZone(date).utcOffset();
  },
  getLastXMonthsForSelect(numberOfMonths = 12) {
    const tmp = []

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < numberOfMonths; i++) {
      const dateByMonth = moment().add(i, 'months')
      const label = `${dateByMonth.year()} ${window.i18n.t(`label.months.${(dateByMonth.month() + 1).toString()}`)}`
      const startDate = `${dateByMonth.startOf('month').format('YYYY-MM-DD')}`
      const endDate = `${dateByMonth.endOf('month').format('YYYY-MM-DD')}`
      tmp.push({
        label,
        value: dateByMonth.format('YYYY-MM'),
        startDate,
        endDate,
      })
    }

    return tmp
  },
}
