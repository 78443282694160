import axios from '@/libs/axios'

const reservationPricingBaseVariableFeeActions = {
    fetchReservationPricingBaseVariableFees({ commit, dispatch }, searchParams) {
        return axios.get('/reservation/pricing/base-variable-fees', { params: searchParams })
    },
    fetchReservationPricingBaseVariableFee({ commit, dispatch }, id) {
        return axios.get(`/reservation/pricing/base-variable-fees/${id}`)
    },
    storeReservationPricingBaseVariableFee({ commit, dispatch }, data) {
        return axios.post('/reservation/pricing/base-variable-fees', data)
    },
    updateReservationPricingBaseVariableFee({ commit, dispatch }, data) {
        return axios.put(`/reservation/pricing/base-variable-fees/${data.id}`, data)
    },
    deleteReservationPricingBaseVariableFee({ commit, dispatch }, id) {
        return axios.delete(`/reservation/pricing/base-variable-fees/${id}`)
    },
    reservationReservationPricingBaseVariableFeeStartDayTypes({}) {
        return axios.get('/reservation/pricing/base-variable-fees/start-day-types')
    },
}

export default reservationPricingBaseVariableFeeActions
