import axios from '@/libs/axios'

const tagActions = {
    fetchTags({ commit, dispatch }, searchParams) {
        // todo fontos get hívásnál hogy a params object key alatt menjenek a keresőparaméterek
        return axios.get('/tags', { params: searchParams })
    },
    fetchAllTagsForBootstrap({ dispatch }) {
        return new Promise((resolve, reject) => {
            const params = {
                page: 1,
                limit: 1000,
                query: '',
                sort: {
                    field: 'id',
                    type: 'desc',
                },
            }

            dispatch('fetchTags', params)
                .then(response => {
                    let list = []
                    if (response.data.tags.data) {
                        for (const key in response.data.tags.data) {
                            list.push({
                                value: parseInt(response.data.tags.data[key].id),
                                title: response.data.tags.data[key].name,
                            })
                        }
                    }

                    resolve(list)
                }).catch(error => {
                reject(error)
            })
        })
    },
    fetchTag({ commit, dispatch }, id) {
        return axios.get(`/tags/${id}`)
    },
    storeTag({ commit, dispatch }, data) {
        return axios.post('/tags', data)
    },
    updateTag({ commit, dispatch }, data) {
        return axios.put(`/tags/${data.id}`, data)
    },
    deleteTag({ commit, dispatch }, id) {
        return axios.delete(`/tags/${id}`)
    },
}

export default tagActions
