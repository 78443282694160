export default [
  {
    path: '/reservation/day-intervals',
    name: 'reservation-day-intervals',
    component: () => import('@/views/pages/reservation/day-interval/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.day-intervals',
      resource: 'reservation',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reservation/day-intervals/add',
    name: 'add-reservation-day-interval',
    component: () => import('@/views/pages/reservation/day-interval/Add.vue'),
    meta: {
      pageTitle: 'breadcrumb.day-intervals',
      resource: 'reservation',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reservation/day-intervals/modify/:id',
    name: 'modify-reservation-day-interval',
    component: () => import('@/views/pages/reservation/day-interval/Modify.vue'),
    meta: {
      pageTitle: 'breadcrumb.day-intervals',
      resource: 'reservation',
      action: 'edit',
      breadcrumb: [
        {
          text: 'breadcrumb.modify',
          active: true,
        },
      ],
    },
  },
]
