export default [
  {
    path: '/record-catches',
    name: 'record-catches',
    component: () => import('@/views/pages/record-catch/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.record-catches',
      resource: 'record_catch',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/record-catches/overview',
    name: 'record-catch-overview',
    component: () => import('@/views/pages/record-catch/Overview.vue'),
    meta: {
      pageTitle: 'breadcrumb.record-catches',
      resource: 'record_catch',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.overview',
          active: true,
        },
      ],
    },
  },
  {
    path: '/record-catches/add',
    name: 'add-record-catch',
    component: () => import('@/views/pages/record-catch/Add.vue'),
    meta: {
      pageTitle: 'breadcrumb.record-catches',
      resource: 'record_catch',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/record-catches/modify/:id',
    name: 'modify-record-catch',
    component: () => import('@/views/pages/record-catch/Modify.vue'),
    meta: {
      pageTitle: 'breadcrumb.record-catches',
      resource: 'record_catch',
      action: 'edit',
      breadcrumb: [
        {
          text: 'breadcrumb.modify',
          active: true,
        },
      ],
    },
  },
]
