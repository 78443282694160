import axios from '@/libs/axios'

const reservationSpecialDayActions = {
    fetchReservationSpecialDays({ commit, dispatch }, searchParams) {
        // todo fontos get hívásnál hogy a params object key alatt menjenek a keresőparaméterek
        return axios.get('/reservation/special-days', { params: searchParams })
    },
    fetchReservationSpecialDay({ commit, dispatch }, id) {
        return axios.get(`/reservation/special-days/${id}`)
    },
    storeReservationSpecialDay({ commit, dispatch }, data) {
        return axios.post('/reservation/special-days', data)
    },
    updateReservationSpecialDay({ commit, dispatch }, data) {
        return axios.put(`/reservation/special-days/${data.id}`, data)
    },
    deleteReservationSpecialDay({ commit, dispatch }, id) {
        return axios.delete(`/reservation/special-days/${id}`)
    },
    reservationSpecialDayTypes({}) {
        return axios.get('/reservation/special-days/types')
    },
}

export default reservationSpecialDayActions
