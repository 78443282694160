export default [
  {
    path: '/reservation/reference-years',
    name: 'reservation-reference-years',
    component: () => import('@/views/pages/reservation/reference-year/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.reference-years',
      resource: 'reservation',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reservation/reference-years/add',
    name: 'add-reservation-reference-year',
    component: () => import('@/views/pages/reservation/reference-year/Add.vue'),
    meta: {
      pageTitle: 'breadcrumb.reference-years',
      resource: 'reservation',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.add',
          active: true,
        },
      ],
    },
  }
]
