import axios from '@/libs/axios'

const reservationPricingTypeActions = {
  fetchReservationPricingTypes({commit, dispatch}, searchParams) {
    return axios.get('/reservation/pricing-types', {params: searchParams})
  },
  fetchPricingTypeList({commit, dispatch}, searchParams) {
    return axios.get('/reservation/pricing-types/list', {params: searchParams})
  },
  fetchPricingTypesForSelect({dispatch}) {
    return new Promise((resolve, reject) => {
      dispatch('fetchPricingTypeList')
        .then(response => {
          const list = []
          if (response.data.entities) {
            for (const key in response.data.entities) {
              list.push({
                value: parseInt(response.data.entities[key].id),
                title: response.data.entities[key].name,
              })
            }
          }

          resolve(list)
        }).catch(error => {
        reject(error)
      })
    })
  },
  fetchReservationPricingType({commit, dispatch}, id) {
    return axios.get(`/reservation/pricing-types/${id}`)
  },
  storeReservationPricingType({commit, dispatch}, data) {
    return axios.post('/reservation/pricing-types', data)
  },
  updateReservationPricingType({commit, dispatch}, data) {
    return axios.put(`/reservation/pricing-types/${data.id}`, data)
  },
  deleteReservationPricingType({commit, dispatch}, id) {
    return axios.delete(`/reservation/pricing-types/${id}`)
  },
}

export default reservationPricingTypeActions
