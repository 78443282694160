import axios from '@/libs/axios'
import i18nHelper from "@/libs/helper/i18nHelper";

const dashboardActions = {
    fetchDashboard({ commit, dispatch }, searchParams) {
        // todo fontos get hívásnál hogy a params object key alatt menjenek a keresőparaméterek
        return axios.get('/dashboard', { params: searchParams })
    },
}

export default dashboardActions
