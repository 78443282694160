import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
// Maconka
import state from './state'
import actions from './maconka/actions'
import mutations from './maconka/mutations'
import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
  // maconka
  state,
  actions,
  mutations,
  getters,
  modules: {
    app,
    appConfig,
    verticalMenu,
  },
  strict: process.env.DEV,
})
