import axios from '@/libs/axios'
import i18nHelper from "@/libs/helper/i18nHelper";

const documentLibraryActions = {
    fetchDocumentLibraries({ commit, dispatch }, searchParams) {
        // todo fontos get hívásnál hogy a params object key alatt menjenek a keresőparaméterek
        return axios.get('/document-libraries', { params: searchParams })
    },
    fetchDocumentLibraryList({ commit, dispatch }, searchParams) {
        // todo fontos get hívásnál hogy a params object key alatt menjenek a keresőparaméterek
        return axios.get('/document-libraries/list', { params: searchParams })
    },
    fetchDocumentLibrary({ commit, dispatch }, id) {
        return axios.get(`/document-libraries/${id}`)
    },
    storeDocumentLibrary({ commit, dispatch }, data) {
        return axios.post('/document-libraries', data)
    },
    updateDocumentLibrary({ commit, dispatch }, data) {
        return axios.put(`/document-libraries/${data.id}`, data)
    },
    copyDocumentLibrary({ commit, dispatch }, data) {
        return axios.post(`/document-libraries/copy/${data.id}`, data)
    },
    deleteDocumentLibrary({ commit, dispatch }, id) {
        return axios.delete(`/document-libraries/${id}`)
    },
    documentLibraryStatuses({}) {
        return axios.get('/document-libraries/statuses')
    },
    documentLibraryTypes({}) {
        return axios.get('/document-libraries/types')
    },
}

export default documentLibraryActions
