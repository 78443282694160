import axios from '@/libs/axios'

const fishermanActions = {
  fetchFishermen({commit, dispatch}, searchParams) {
    // todo fontos get hívásnál hogy a params object key alatt menjenek a keresőparaméterek
    return axios.get('/fishermen', {params: searchParams})
  },
  fetchFishermenForSelect({dispatch}) {
    return new Promise((resolve, reject) => {
      axios.get('/fishermen/list')
        .then(response => {
          const list = []

          if (response.data.entities) {
            for (const key in response.data.entities) {
              list.push({
                value: parseInt(key),
                title: response.data.entities[key],
              })
            }
          }

          resolve(list)
        }).catch(error => {
        reject(error)
      })
    })
  },
  fetchNationalitiesForSelect() {
    return new Promise((resolve, reject) => {
      axios.get('/fishermen/nationalities/list')
        .then(response => {

          let nationalities = require("i18n-iso-countries")
          nationalities.registerLocale(require("i18n-iso-countries/langs/hu.json"));

          const list = []

          if (response.data.values) {
            for (const key in response.data.values) {
              list.push({
                value: response.data.values[key].value,
                title: nationalities.getName(response.data.values[key].title, "hu"),
              })
            }
          }

          resolve(list)
        }).catch(error => {
        reject(error)
      })
    })
  },
  fetchFisherman({commit, dispatch}, id) {
    return axios.get(`/fishermen/${id}`)
  },
  storeFisherman({commit, dispatch}, data) {
    return axios.post('/fishermen', data)
  },
  updateFisherman({commit, dispatch}, data) {
    return axios.put(`/fishermen/${data.id}`, data)
  },
  deleteFisherman({commit, dispatch}, id) {
    return axios.delete(`/fishermen/${id}`)
  },
}

export default fishermanActions
