export default [
  {
    path: '/reservation-pricing',
    name: 'reservation-pricing',
    component: () => import('@/views/pages/reservation/pricing/Modify.vue'),
    meta: {
      pageTitle: 'breadcrumb.pricing',
      resource: 'reservation',
      action: 'edit',
      breadcrumb: [
        {
          text: 'breadcrumb.modify',
          active: true,
        },
      ],
    },
  },
]
