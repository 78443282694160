import axios from '@/libs/axios'

const textTemplateActions = {
    fetchTextTemplates({ commit, dispatch }, searchParams) {
        // todo fontos get hívásnál hogy a params object key alatt menjenek a keresőparaméterek
        return axios.get('/text-templates', { params: searchParams })
    },
    fetchTextTemplate({ commit, dispatch }, id) {
        return axios.get(`/text-templates/${id}`)
    },
    updateTextTemplate({ commit, dispatch }, data) {
        return axios.put(`/text-templates/${data.id}`, data)
    },
}

export default textTemplateActions
