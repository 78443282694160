import axios from '@/libs/axios'

const reservationCalendarActions = {
    fetchCalendarDays({ commit, dispatch }, searchParams) {
        return axios.get('/reservation/days', { params: searchParams })
    },
    fetchCalendarPopovers({ commit, dispatch }, searchParams) {
        return axios.get('/reservation/popovers', { params: searchParams })
    },
}

export default reservationCalendarActions
