import axios from '@/libs/axios'

export default {
    fetchMeteorologyDataSources({ commit, dispatch }, searchParams) {
        return axios.get('/meteorology-data-source', { params: searchParams })
    },
    fetchMeteorologyDataSource({ commit, dispatch }, id) {
        return axios.get(`/meteorology-data-source/${id}`)
    },
    storeMeteorologyDataSource({ commit, dispatch }, data) {
        return axios.post('/meteorology-data-source', data)
    },
    updateMeteorologyDataSource({ commit, dispatch }, data) {
        return axios.put(`/meteorology-data-source/${data.id}`, data)
    },
    deleteMeteorologyDataSource({ commit, dispatch }, id) {
        return axios.delete(`/meteorology-data-source/${id}`)
    },
    fetchMeteorologyDataSourceDataTypes({}) {
        return axios.get('meteorology/data-source/types')
    },
}
