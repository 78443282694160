import axios from '@/libs/axios'

const pollActions = {
    fetchPolls({ commit, dispatch }, searchParams) {
        // todo fontos get hívásnál hogy a params object key alatt menjenek a keresőparaméterek
        return axios.get('/polls', { params: searchParams })
    },
    fetchPollAnswers({ commit, dispatch }, searchParams) {
        // todo fontos get hívásnál hogy a params object key alatt menjenek a keresőparaméterek
        return axios.get('/polls/answers', { params: searchParams })
    },
    fetchPoll({ commit, dispatch }, id) {
        return axios.get(`/polls/${id}`)
    },
    storePoll({ commit, dispatch }, data) {
        return axios.post('/polls', data)
    },
    updatePoll({ commit, dispatch }, data) {
        return axios.put(`/polls/${data.id}`, data)
    },
    deletePoll({ commit, dispatch }, id) {
        return axios.delete(`/polls/${id}`)
    },
    pollTypes({}) {
        return axios.get('/polls/types')
    },
}

export default pollActions
