import axios from '@/libs/axios'

const fishTypeActions = {
    fetchFishTypes({ commit, dispatch }, searchParams) {
        // todo fontos get hívásnál hogy a params object key alatt menjenek a keresőparaméterek
        return axios.get('/fish-types', { params: searchParams })
    },
    fetchFishTypesForSelect({dispatch }) {
        return new Promise((resolve, reject) => {
            axios.get('/fish-types/list')
              .then(response => {
                  const list = []

                  if (response.data.entities) {
                      for (const key in response.data.entities) {
                          list.push({
                              value: parseInt(key),
                              title: response.data.entities[key],
                          })
                      }
                  }

                  resolve(list)
              }).catch(error => {
                reject(error)
            })
        })
    },
    fetchFishType({ commit, dispatch }, id) {
        return axios.get(`/fish-types/${id}`)
    },
    storeFishType({ commit, dispatch }, data) {
        return axios.post('/fish-types', data)
    },
    updateFishType({ commit, dispatch }, data) {
        return axios.put(`/fish-types/${data.id}`, data)
    },
    deleteFishType({ commit, dispatch }, id) {
        return axios.delete(`/fish-types/${id}`)
    },
}

export default fishTypeActions
