import axios from '@/libs/axios'

export default {
    fetchMessageGroups({ commit, dispatch }, searchParams) {
        return axios.get('/messages', { params: searchParams })
    },
    fetchMessagesUnseenGroupCnt({ commit, dispatch }) {
        return axios.get('/messages/countUnread')
    },
    fetchMessageEntityTypes({ commit, dispatch }) {
        return axios.get('/messages/entityTypes')
    },
    fetchEntitiesByEntityType({ commit, dispatch }, data) {
        return axios.get(`/messages/entities/${data.entityType}`, { params: data })
    },
    fetchMessageGroup({ commit, dispatch }, data) {
        return axios.get(`/messages/${data.messageGroupID}`, { params: data })
    },
    storeMessage({ commit, dispatch }, data) {
        return axios.post('messages', data)
    },
    replyToMessage({ commit, dispatch }, data) {
        return axios.post(`messages/${data.messageGroupId}/reply`, data)
    },
    seeMessage({ commit, dispatch }, messageGroupId) {
        return axios.post(`messages/${messageGroupId}/seen`)
    },
}
