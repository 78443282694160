import axios from '@/libs/axios'

const reservationEventActions = {
    fetchReservationEvents({ commit, dispatch }, searchParams) {
        // todo fontos get hívásnál hogy a params object key alatt menjenek a keresőparaméterek
        return axios.get('/reservation/events', { params: searchParams })
    },
    fetchReservationEvent({ commit, dispatch }, id) {
        return axios.get(`/reservation/events/${id}`)
    },
    storeReservationEvent({ commit, dispatch }, data) {
        return axios.post('/reservation/events', data)
    },
    updateReservationEvent({ commit, dispatch }, data) {
        return axios.put(`/reservation/events/${data.id}`, data)
    },
    deleteReservationEvent({ commit, dispatch }, id) {
        return axios.delete(`/reservation/events/${id}`)
    },
}

export default reservationEventActions
