const getters = {
  user: state => state.user,
  userID: state => (state.user && state.user.id ? state.user.id : null),
  userName: state => (state.user && state.user.name ? state.user.name : 'Anonim'),
  userRole: state => 'Admin',
  userPermissions: state => (state.user && state.user.permissions ? state.user.permissions : null),
  isLogged: state => !!state.user,
  token: state => state.token,
  unreadMessageGroups: state => (state.user && state.user.unread ? state.user.unread : 0),
}

export default getters
