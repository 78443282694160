import axios from '@/libs/axios'

const partnerActions = {
    fetchPartners({ commit, dispatch }, searchParams) {
        // todo fontos get hívásnál hogy a params object key alatt menjenek a keresőparaméterek
        return axios.get('/partners', { params: searchParams })
    },
    fetchPartner({ commit, dispatch }, id) {
        return axios.get(`/partners/${id}`)
    },
    storePartner({ commit, dispatch }, data) {
        return axios.post('/partners', data)
    },
    updatePartner({ commit, dispatch }, data) {
        return axios.put(`/partners/${data.id}`, data)
    },
    deletePartner({ commit, dispatch }, id) {
        return axios.delete(`/partners/${id}`)
    },
}

export default partnerActions
