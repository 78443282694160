export default [
  {
    path: '/email-templates',
    name: 'email-templates',
    component: () => import('@/views/pages/email-template/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.email-templates',
      resource: 'email_template',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/email-templates/modify/:id',
    name: 'modify-email-template',
    component: () => import('@/views/pages/email-template/Modify.vue'),
    meta: {
      pageTitle: 'breadcrumb.email-templates',
      resource: 'email_template',
      action: 'edit',
      breadcrumb: [
        {
          text: 'breadcrumb.modify',
          active: true,
        },
      ],
    },
  },
]
