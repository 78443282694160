import axios from '@/libs/axios'

export default {
    fetchMeteorologyTabData({}) {
        return axios.get('/meteorology/tab-data/meta')
    },
    fetchMeteorologyDataByType({ commit, dispatch }, searchParams) {
        return axios.get(`/meteorology/tab-data/${searchParams.type}`, { params: searchParams })
    },
    storeMeteorologyData({ commit, dispatch }, data) {
        return axios.post('/meteorology', data)
    },
    updateMeteorologyData({ commit, dispatch }, data) {
        return axios.put(`/meteorology/${data.id}`, data)
    },
    deleteMeteorologyData({ commit, dispatch }, id) {
        return axios.delete(`/meteorology/${id}`)
    },
}
