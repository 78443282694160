export default [
  {
    path: '/document-libraries',
    name: 'document-libraries',
    component: () => import('@/views/pages/document-library/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.document-libraries',
      resource: 'document_library',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/document-libraries/add',
    name: 'add-document-library',
    component: () => import('@/views/pages/document-library/Add.vue'),
    meta: {
      pageTitle: 'breadcrumb.document-libraries',
      resource: 'document_library',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/document-libraries/modify/:id',
    name: 'modify-document-library',
    component: () => import('@/views/pages/document-library/Modify.vue'),
    meta: {
      pageTitle: 'breadcrumb.document-libraries',
      resource: 'document_library',
      action: 'edit',
      breadcrumb: [
        {
          text: 'breadcrumb.modify',
          active: true,
        },
      ],
    },
  },
]
