export default [
  {
    path: '/text-templates',
    name: 'text-templates',
    component: () => import('@/views/pages/text-template/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.text-templates',
      resource: 'text_template',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/text-templates/modify/:id',
    name: 'modify-text-template',
    component: () => import('@/views/pages/text-template/Modify.vue'),
    meta: {
      pageTitle: 'breadcrumb.text-templates',
      resource: 'text_template',
      action: 'edit',
      breadcrumb: [
        {
          text: 'breadcrumb.modify',
          active: true,
        },
      ],
    },
  },
]
