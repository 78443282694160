import axios from '@/libs/axios'

const userActions = {
    fetchUsers({ commit, dispatch }, searchParams) {
        return axios.get('/users', { params: searchParams })
    },
    fetchUserList({ commit, dispatch }, searchParams) {
        return axios.get('/users/list', { params: searchParams })
    },
    fetchUsersForTableFilter({dispatch}) {
        return new Promise((resolve, reject) => {
            dispatch('fetchUserList')
              .then(response => {
                  const list = []

                  if (response.data.entities) {
                      for (const key in response.data.entities) {
                          list.push({
                              value: response.data.entities[key].id,
                              text: response.data.entities[key].name+' ('+response.data.entities[key].id+')',
                          })
                      }
                  }

                  resolve(list)
              }).catch(error => {
                reject(error)
            })
        })
    },
    fetchUsersForSelect({commit, dispatch}, searchParams) {
        return new Promise((resolve, reject) => {
            dispatch('fetchUsers', searchParams)
                .then(response => {
                    const list = []

                    if (response.data.entities.data.length) {
                        for (const key in response.data.entities.data) {
                            list.push({
                                value: parseInt(response.data.entities.data[key].id),
                                title: response.data.entities.data[key].name + ' (' + response.data.entities.data[key].username + ', '+response.data.entities.data[key].email+', '+response.data.entities.data[key].id+')',
                                id: response.data.entities.data[key].id,
                                name: response.data.entities.data[key].name,
                                username: response.data.entities.data[key].username,
                                email: response.data.entities.data[key].email,
                                phone: response.data.entities.data[key].phone,
                                country: response.data.entities.data[key].country,
                                zip: response.data.entities.data[key].zip,
                                city: response.data.entities.data[key].city,
                                address1: response.data.entities.data[key].address_1,
                                address2: response.data.entities.data[key].address_2,
                            })
                        }
                    }

                    resolve(list)
                }).catch(error => {
                reject(error)
            })
        })
    },
    fetchUserTitlesForSelect({dispatch}) {
        return new Promise((resolve, reject) => {
            axios.get('/users/titles')
              .then(response => {
                  const list = []

                  if (response.data.values) {
                      for (const key in response.data.values) {
                          list.push({
                              value: response.data.values[key].value,
                              title: response.data.values[key].title,
                          })
                      }
                  }

                  resolve(list)
              }).catch(error => {
                reject(error)
            })
        })
    },
    fetchUser({ commit, dispatch }, id) {
        return axios.get(`/users/${id}`)
    },
    storeUser({ commit, dispatch }, data) {
        return axios.post('/users', data)
    },
    updateUser({ commit, dispatch }, data) {
        return axios.put(`/users/${data.id}`, data)
    },
    deleteUser({ commit, dispatch }, id) {
        return axios.delete(`/users/${id}`)
    },
}

export default userActions
