import axios from '@/libs/axios'

export default {
  fetchNewsletters({commit, dispatch}, searchParams) {
    return axios.get('/newsletters', {params: searchParams})
  },
  fetchRecipientGroupsForSelect({dispatch}) {
    return new Promise((resolve, reject) => {
      axios.get('/newsletters/recipient-groups')
        .then(response => {
          const list = []

          if (response.data.values) {
            for (const key in response.data.values) {
              list.push({
                value: response.data.values[key].value,
                title: response.data.values[key].title,
              })
            }
          }

          resolve(list)
        }).catch(error => {
        reject(error)
      })
    })
  },
  fetchNewsletter({commit, dispatch}, id) {
    return axios.get(`/newsletters/${id}`)
  },
  storeNewsletter({commit, dispatch}, data) {
    return axios.post('/newsletters', data)
  },
  // típusból készít egy hírlevél példányt
  storeNewsletterDraft({commit, dispatch}, data) {
    return axios.post(`/newsletters/add/${data.entityType}/${data.entityId}`)
  },
  updateNewsletter({commit, dispatch}, data) {
    return axios.put(`/newsletters/${data.id}`, data)
  },
  newsletterTestSend({commit, dispatch}, data) {
    return axios.post(`/newsletters/test-send/${data.id}`, data)
  },
  newsletterSend({commit, dispatch}, data) {
    return axios.post(`/newsletters/send/${data.id}`, data)
  },
  deleteNewsletter({commit, dispatch}, id) {
    return axios.delete(`/newsletters/${id}`)
  },
}
