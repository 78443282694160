import axios from '@/libs/axios'
import helpers from '@/libs/helper'

import permissionActions from '@/store/maconka/actions/permissionActions'
import roleActions from '@/store/maconka/actions/roleActions'
import appActions from '@/store/maconka/actions/appActions'
import authActions from '@/store/maconka/actions/authActions'
import userActions from '@/store/maconka/actions/userActions'
import articleActions from '@/store/maconka/actions/articleActions'
import fileActions from '@/store/maconka/actions/fileActions'
import tagActions from "@/store/maconka/actions/tagActions";
import logActions from "@/store/maconka/actions/logActions";
import documentLibraryActions from "@/store/maconka/actions/documentLibraryActions";
import messageActions from "@/store/maconka/actions/messageActions";
import store from '@/store'
import entityActions from "@/store/maconka/actions/entityActions";
import fishermanActions from "@/store/maconka/actions/fishermanActions";
import fishCategoryActions from "@/store/maconka/actions/fishCategoryActions";
import fishTypeActions from "@/store/maconka/actions/fishTypeActions";
import locationActions from "@/store/maconka/actions/locationActions";
import baitActions from "@/store/maconka/actions/baitActions";
import recordCatchActions from "@/store/maconka/actions/recordCatchActions";
import galleryActions from "@/store/maconka/actions/galleryActions";
import textTemplateActions from "@/store/maconka/actions/textTemplateActions";
import emailTemplateActions from "@/store/maconka/actions/emailTemplateActions";
import optionActions from "@/store/maconka/actions/optionActions";
import reservationFishingSpotActions from "@/store/maconka/actions/reservationFishingSpotActions";
import reservationReferenceYearActions from "@/store/maconka/actions/reservationReferenceYearActions";
import reservationPricingTypeActions from "@/store/maconka/actions/reservationPricingTypeActions";
import reservationEventActions from "@/store/maconka/actions/reservationEventActions";
import reservationDayIntervalActions from "@/store/maconka/actions/reservationDayIntervalActions";
import reservationSpecialDayActions from "@/store/maconka/actions/reservationSpecialDayActions";
import reservationPricingBaseVariableFeeActions from "@/store/maconka/actions/reservationPricingBaseVariableFeeActions";
import reservationPricingActions from "@/store/maconka/actions/reservationPricingActions";
import reservationMapActions from "@/store/maconka/actions/reservationMapActions";
import reservationActions from "@/store/maconka/actions/reservationActions";
import reservationCalendarActions from "@/store/maconka/actions/reservationCalendarActions";
import meteorologyActions from "@/store/maconka/actions/meteorologyActions";
import meteorologyDataSourceActions from "@/store/maconka/actions/meteorologyDataSourceActions";
import newsletterActions from "@/store/maconka/actions/newsletterActions";
import partnerActions from "@/store/maconka/actions/partnerActions";
import pollActions from "@/store/maconka/actions/pollActions";
import dashboardActions from "@/store/maconka/actions/dashboardActions";

// setup axios
axios.interceptors.request.use(
    config => {
        const accessToken = store.state.token
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`
        }
        // TODO: basic auth
        if (process.env.NODE_ENV === 'development') console.log('Interceptor request[',config.method , '+',config.url ,']', config);
        return config
    },
    error => {
        // Do something with request error
        helpers.showErrorToast('', 'Ismeretlen hiba! Keresse fel az oldal üzemeltetőjét')
        if (process.env.NODE_ENV === 'development') console.error('Interceptor request error: ', error);
        return Promise.reject(error)
    },
)

axios.interceptors.response.use(
    //If we have a response from our recent http call
    (response) => {
        if (process.env.NODE_ENV === 'development') console.log('Interceptor response[',response.config.method , '+',response.config.url ,']', response);
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            helpers.showErrorToast('Lejárt munkamenet!')
            helpers.logout()
            // eslint-disable-next-line no-underscore-dangle
        }
        //Catches 403 error from our axios request
        if (process.env.NODE_ENV === 'development') console.error('Interceptor response error: ', error);
        return Promise.reject(error);
    }
);

const actions = {
    ...permissionActions,
    ...roleActions,
    ...appActions,
    ...authActions,
    ...userActions,
    ...articleActions,
    ...fileActions,
    ...tagActions,
    ...logActions,
    ...documentLibraryActions,
    ...entityActions,
    ...messageActions,
    ...fishermanActions,
    ...fishCategoryActions,
    ...fishTypeActions,
    ...locationActions,
    ...baitActions,
    ...recordCatchActions,
    ...galleryActions,
    ...textTemplateActions,
    ...emailTemplateActions,
    ...optionActions,
    ...reservationReferenceYearActions,
    ...reservationFishingSpotActions,
    ...reservationPricingTypeActions,
    ...reservationEventActions,
    ...reservationDayIntervalActions,
    ...reservationSpecialDayActions,
    ...reservationPricingBaseVariableFeeActions,
    ...reservationPricingActions,
    ...reservationMapActions,
    ...reservationActions,
    ...meteorologyActions,
    ...meteorologyDataSourceActions,
    ...newsletterActions,
    ...reservationCalendarActions,
    ...partnerActions,
    ...pollActions,
    ...dashboardActions,
}

export default actions
