import axios from '@/libs/axios'

const locationActions = {
    fetchLocations({ commit, dispatch }, searchParams) {
        // todo fontos get hívásnál hogy a params object key alatt menjenek a keresőparaméterek
        return axios.get('/locations', { params: searchParams })
    },
    fetchLocationsForSelect({dispatch }) {
        return new Promise((resolve, reject) => {
            axios.get('/locations/list')
              .then(response => {
                  const list = []

                  if (response.data.entities) {
                      for (const key in response.data.entities) {
                          list.push({
                              value: parseInt(key),
                              title: response.data.entities[key],
                          })
                      }
                  }

                  resolve(list)
              }).catch(error => {
                reject(error)
            })
        })
    },
    fetchLocation({ commit, dispatch }, id) {
        return axios.get(`/locations/${id}`)
    },
    storeLocation({ commit, dispatch }, data) {
        return axios.post('/locations', data)
    },
    updateLocation({ commit, dispatch }, data) {
        return axios.put(`/locations/${data.id}`, data)
    },
    deleteLocation({ commit, dispatch }, id) {
        return axios.delete(`/locations/${id}`)
    },
}

export default locationActions
