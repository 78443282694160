export default [
  {
    path: '/reservation/maps',
    name: 'reservation-maps',
    component: () => import('@/views/pages/reservation/maps/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.maps',
      resource: 'reservation',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reservation/maps/add',
    name: 'add-reservation-map',
    component: () => import('@/views/pages/reservation/maps/Add.vue'),
    meta: {
      pageTitle: 'breadcrumb.maps',
      resource: 'reservation',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reservation/maps/modify/:id',
    name: 'modify-reservation-map',
    component: () => import('@/views/pages/reservation/maps/Modify.vue'),
    meta: {
      pageTitle: 'breadcrumb.maps',
      resource: 'reservation',
      action: 'edit',
      breadcrumb: [
        {
          text: 'breadcrumb.modify',
          active: true,
        },
      ],
    },
  },
]
