import axios from '@/libs/axios'

const reservationReferenceYearActions = {
  fetchReferenceYears({ commit, dispatch }, searchParams) {
    // todo fontos get hívásnál hogy a params object key alatt menjenek a keresőparaméterek
    return axios.get('/reservation/reference-years', { params: searchParams })
  },
  fetchReferenceYearList({commit, dispatch}, searchParams) {
    return axios.get('/reservation/reference-years/list', {params: searchParams})
  },
  storeReferenceYear({ commit, dispatch }, data) {
    return axios.post('/reservation/reference-years', data)
  },
  fetchReferenceYearsForSelect({dispatch}) {
    return new Promise((resolve, reject) => {
      dispatch('fetchReferenceYearList')
        .then(response => {
          const list = []
          if (response.data.entities) {
            for (const key in response.data.entities) {
              list.push({
                value: parseInt(response.data.entities[key].id),
                title: response.data.entities[key].year,
              })
            }
          }

          resolve(list)
        }).catch(error => {
        reject(error)
      })
    })
  },
  fetchReferenceYearsForTable({dispatch}) {
    return new Promise((resolve, reject) => {
      dispatch('fetchReferenceYearList')
        .then(response => {
          const list = []
          if (response.data.entities) {
            for (const key in response.data.entities) {
              list.push({
                value: parseInt(response.data.entities[key].id),
                text: response.data.entities[key].year,
              })
            }
          }

          resolve(list)
        }).catch(error => {
        reject(error)
      })
    })
  },
}

export default reservationReferenceYearActions
