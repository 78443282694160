export default [
  {
    path: '/articles/cover',
    name: 'articles-cover',
    component: () => import('@/views/pages/article/Cover.vue'),
    meta: {
      pageTitle: 'breadcrumb.articles',
      resource: 'article',
      action: 'edit',
      breadcrumb: [
        {
          text: 'breadcrumb.cover',
          active: true,
        },
      ],
    },
  },
  {
    path: '/articles',
    name: 'articles',
    component: () => import('@/views/pages/article/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.articles',
      resource: 'article',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/articles/add',
    name: 'add-article',
    component: () => import('@/views/pages/article/Add.vue'),
    meta: {
      pageTitle: 'breadcrumb.articles',
      resource: 'article',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/articles/modify/:id',
    name: 'modify-article',
    component: () => import('@/views/pages/article/Modify.vue'),
    meta: {
      pageTitle: 'breadcrumb.articles',
      resource: 'article',
      action: 'edit',
      breadcrumb: [
        {
          text: 'breadcrumb.modify',
          active: true,
        },
      ],
    },
  },
]
