export default [
  {
    path: '/messages',
    name: 'messages',
    component: () => import('@/views/pages/message/Chat.vue'),
    meta: {
      pageTitle: 'breadcrumb.messages',
      resource: 'message',
      action: 'admin',
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
      breadcrumb: [
        {
          text: 'breadcrumb.messages',
          active: true,
        },
      ],
    },
  },
  {
    path: '/messages/add',
    name: 'add-message',
    component: () => import('@/views/pages/message/Add.vue'),
    meta: {
      pageTitle: 'breadcrumb.add-message',
      resource: 'message',
      action: 'admin',
      breadcrumb: [
        {
          text: 'breadcrumb.add-message',
          active: true,
        },
      ],
    },
  },
]
