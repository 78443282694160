import axios from '@/libs/axios'

const recordCatchActions = {
    fetchRecordCatches({ commit, dispatch }, searchParams) {
        // todo fontos get hívásnál hogy a params object key alatt menjenek a keresőparaméterek
        return axios.get('/record-catches', { params: searchParams })
    },
    fetchRecordCatch({ commit, dispatch }, id) {
        return axios.get(`/record-catches/${id}`)
    },
    storeRecordCatch({ commit, dispatch }, data) {
        return axios.post('/record-catches', data)
    },
    updateRecordCatch({ commit, dispatch }, data) {
        return axios.put(`/record-catches/${data.id}`, data)
    },
    deleteRecordCatch({ commit, dispatch }, id) {
        return axios.delete(`/record-catches/${id}`)
    },
}

export default recordCatchActions
