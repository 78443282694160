export default [
  {
    path: '/reservation/special-days',
    name: 'reservation-special-days',
    component: () => import('@/views/pages/reservation/special-day/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.special-days',
      resource: 'reservation',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reservation/special-days/add',
    name: 'add-reservation-special-day',
    component: () => import('@/views/pages/reservation/special-day/Add.vue'),
    meta: {
      pageTitle: 'breadcrumb.special-days',
      resource: 'reservation',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reservation/special-days/modify/:id',
    name: 'modify-reservation-special-day',
    component: () => import('@/views/pages/reservation/special-day/Modify.vue'),
    meta: {
      pageTitle: 'breadcrumb.special-days',
      resource: 'reservation',
      action: 'edit',
      breadcrumb: [
        {
          text: 'breadcrumb.modify',
          active: true,
        },
      ],
    },
  },
]
