import axios from '@/libs/axios'
import i18nHelper from "@/libs/helper/i18nHelper";

const articleActions = {
    fetchArticles({ commit, dispatch }, searchParams) {
        // todo fontos get hívásnál hogy a params object key alatt menjenek a keresőparaméterek
        return axios.get('/articles', { params: searchParams })
    },
    fetchArticleList({ commit, dispatch }, searchParams) {
        // todo fontos get hívásnál hogy a params object key alatt menjenek a keresőparaméterek
        return axios.get('/articles/list', { params: searchParams })
    },
    fetchArticlesForSelect({ dispatch }) {
        return new Promise((resolve, reject) => {
            dispatch('fetchArticleList')
                .then(response => {
                    const list = []

                    if (response.data.entities) {
                        for (const key in response.data.entities) {
                            list.push({
                                value: parseInt(response.data.entities[key].id),
                                title: response.data.entities[key].title,
                            })
                        }
                    }

                    resolve(list)
                }).catch(error => {
                reject(error)
            })
        })
    },
    fetchArticle({ commit, dispatch }, id) {
        return axios.get(`/articles/${id}`)
    },
    storeArticle({ commit, dispatch }, data) {
        return axios.post('/articles', data)
    },
    updateArticle({ commit, dispatch }, data) {
        return axios.put(`/articles/${data.id}`, data)
    },
    updateArticleVideos({ commit, dispatch }, data) {
        return axios.post(`/articles/${data.id}/update-videos`, data)
    },
    addRelatedArticle({ commit, dispatch }, data) {
        return axios.post(`/articles/${data.id}/related/${data.relatedId}`)
    },
    deleteRelatedArticle({ commit, dispatch }, data) {
        return axios.delete(`/articles/${data.id}/related/${data.relatedId}`)
    },
    updateRelatedArticles({ commit, dispatch }, data) {
        return axios.post(`/articles/${data.id}/update-related-articles`, data)
    },
    deleteArticle({ commit, dispatch }, id) {
        return axios.delete(`/articles/${id}`)
    },
    articleStatuses({}) {
        return axios.get('/articles/statuses')
    },
    orderArticle({ commit, dispatch }, data) {
        return axios.post('/articles/order', data)
    },
    manualOrderArticle({ commit, dispatch }, data) {
        return axios.post('/articles/order/manual', data)
    },
}

export default articleActions
