import axios from '@/libs/axios'

const reservationDayIntervalActions = {
    fetchReservationDayIntervals({ commit, dispatch }, searchParams) {
        // todo fontos get hívásnál hogy a params object key alatt menjenek a keresőparaméterek
        return axios.get('/reservation/day-intervals', { params: searchParams })
    },
    fetchReservationDayInterval({ commit, dispatch }, id) {
        return axios.get(`/reservation/day-intervals/${id}`)
    },
    storeReservationDayInterval({ commit, dispatch }, data) {
        return axios.post('/reservation/day-intervals', data)
    },
    updateReservationDayInterval({ commit, dispatch }, data) {
        return axios.put(`/reservation/day-intervals/${data.id}`, data)
    },
    deleteReservationDayInterval({ commit, dispatch }, id) {
        return axios.delete(`/reservation/day-intervals/${id}`)
    },
}

export default reservationDayIntervalActions
