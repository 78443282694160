export default {
  addPostfix(string = '', size = 'auto', postfix = '...') {
    if (!string.length) {
      return '-'
    }

    let addPostfix = true
    let tmp = string

    if (size !== 'auto') {
      const postfixLength = postfix.length
      tmp = string.substr(0, size - postfixLength)

      if (tmp === string) {
        addPostfix = false
      }
    }

    return tmp + (addPostfix ? postfix : '')
  },
  replaceBlankText(text = '', replaceCharacter = '-') {
    if (text === null || text.length === 0) {
      return replaceCharacter
    }

    return text
  },
  slug(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

    return str;
  },
  removeTags(str) {
    if ((str === null) || (str === ''))
      return false;
    else
      str = str.toString();

    return str.replace(/(<([^>]+)>)/ig, '');
  },
  leadExcerpt(str) {
    if (str.length > 70) {
      str = str.substring(0, 70) + "...";
    }
    return str;
  }
}
