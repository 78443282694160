import axios from '@/libs/axios'

const entityActions = {
  copyEntity({ commit, dispatch }, data) {
    return axios.post(`/entities/copy/${data.type}/${data.id}`, data)
  },

  getImagesForEntity({ commit, dispatch }, data) {
    return axios.get(`/entities/images/${data.type}/${data.id}`)
  },

  orderImagesForEntity({ commit, dispatch }, data) {
    return axios.post(`/entities/images/order/${data.type}/${data.id}`, data)
  },

  getFilesForEntity({ commit, dispatch }, data) {
    return axios.get(`/entities/files/${data.type}/${data.id}`)
  },

  orderFilesForEntity({ commit, dispatch }, data) {
    return axios.post(`/entities/files/order/${data.type}/${data.id}`, data)
  },
}

export default entityActions
