import axios from '@/libs/axios'

const fishCategoryActions = {
    fetchFishCategories({ commit, dispatch }, searchParams) {
        // todo fontos get hívásnál hogy a params object key alatt menjenek a keresőparaméterek
        return axios.get('/fish-categories', { params: searchParams })
    },
    fetchFishCategoryList({ commit, dispatch }, searchParams) {
        // todo fontos get hívásnál hogy a params object key alatt menjenek a keresőparaméterek
        return axios.get('/fish-categories/list', { params: searchParams })
    },
    fetchFishCategoriesForSelect({ dispatch }) {
        return new Promise((resolve, reject) => {
            dispatch('fetchFishCategoryList')
              .then(response => {
                  const list = []

                  if (response.data.entities) {
                      for (const key in response.data.entities) {
                          list.push({
                              value: parseInt(response.data.entities[key].id),
                              title: response.data.entities[key].name,
                          })
                      }
                  }

                  resolve(list)
              }).catch(error => {
                reject(error)
            })
        })
    },
    fetchFishCategory({ commit, dispatch }, id) {
        return axios.get(`/fish-categories/${id}`)
    },
    storeFishCategory({ commit, dispatch }, data) {
        return axios.post('/fish-categories', data)
    },
    updateFishCategory({ commit, dispatch }, data) {
        return axios.put(`/fish-categories/${data.id}`, data)
    },
    deleteFishCategory({ commit, dispatch }, id) {
        return axios.delete(`/fish-categories/${id}`)
    },
}

export default fishCategoryActions
