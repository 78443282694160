import axios from '@/libs/axios'

const baitActions = {
    fetchBaits({ commit, dispatch }, searchParams) {
        // todo fontos get hívásnál hogy a params object key alatt menjenek a keresőparaméterek
        return axios.get('/baits', { params: searchParams })
    },
    fetchBaitsForSelect({dispatch }) {
        return new Promise((resolve, reject) => {
            axios.get('/baits/list')
              .then(response => {
                  const list = []

                  if (response.data.entities) {
                      for (const key in response.data.entities) {
                          list.push({
                              value: parseInt(key),
                              title: response.data.entities[key],
                          })
                      }
                  }

                  resolve(list)
              }).catch(error => {
                reject(error)
            })
        })
    },
    fetchBait({ commit, dispatch }, id) {
        return axios.get(`/baits/${id}`)
    },
    storeBait({ commit, dispatch }, data) {
        return axios.post('/baits', data)
    },
    updateBait({ commit, dispatch }, data) {
        return axios.put(`/baits/${data.id}`, data)
    },
    deleteBait({ commit, dispatch }, id) {
        return axios.delete(`/baits/${id}`)
    },
}

export default baitActions
