export default [
  {
    path: '/meteorology/data/list',
    name: 'meteorology-data',
    component: () => import('@/views/pages/meteorology/data/Manager.vue'),
    meta: {
      pageTitle: 'breadcrumb.meteorology',
      resource: 'meteorology',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.datas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/meteorology/datasource/list',
    name: 'list-meteorology-datasource',
    component: () => import('@/views/pages/meteorology/dataSource/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.meteorology',
      resource: 'meteorology',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.meteorologyDataSourceList',
          active: true,
        },
      ],
    },
  },
  {
    path: '/meteorology/datasource/add',
    name: 'add-meteorology-datasource',
    component: () => import('@/views/pages/meteorology/dataSource/Add.vue'),
    meta: {
      pageTitle: 'breadcrumb.meteorology',
      resource: 'meteorology',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.addMeteorologyDataSource',
          active: true,
        },
      ],
    },
  },
  {
    path: '/meteorology/datasource/modify/:id',
    name: 'modify-meteorology-datasource',
    component: () => import('@/views/pages/meteorology/dataSource/Modify.vue'),
    meta: {
      pageTitle: 'breadcrumb.meteorology',
      resource: 'meteorology',
      action: 'edit',
      breadcrumb: [
        {
          text: 'breadcrumb.modifyMeteorologyDataSource',
          active: true,
        },
      ],
    },
  },
]
