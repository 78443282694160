import storageHelper from '@/libs/helper/storageHelper'
import authHelper from '@/libs/helper/authHelper'
import cryptHelper from '@/libs/helper/cryptHelper'
import toastHelper from '@/libs/helper/toastHelper'
import stringHelper from '@/libs/helper/stringHelper'
import dateHelper from '@/libs/helper/dateHelper'
import systemHelper from '@/libs/helper/systemHelper'
import formHelper from '@/libs/helper/formHelper'

export default {
  ...storageHelper,
  ...authHelper,
  ...cryptHelper,
  ...toastHelper,
  ...stringHelper,
  ...dateHelper,
  ...systemHelper,
  ...formHelper,
}
