export default {
    formatPriceText(value, digits = 0) {
        let val;
        if(value !== 0){
            val = (value / 1).toFixed(digits)
        }else{
            val = value
        }
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },
}
