import axios from '@/libs/axios'

const optionActions = {
    fetchOptions({ commit, dispatch }, searchParams) {
        // todo fontos get hívásnál hogy a params object key alatt menjenek a keresőparaméterek
        return axios.get('/options', { params: searchParams })
    },
    fetchOption({ commit, dispatch }, id) {
        return axios.get(`/options/${id}`)
    },
    updateOption({ commit, dispatch }, data) {
        if(data instanceof FormData && data.get('type') === 'image'){
            /**
             * @Important: if the method is PUT in Laravel, normal javascript FormData is not working. Here is the solution:
             */
            data.append('_method', 'put')
            return axios.post('/options/'+ data.get('id'), data)
        }else{
            return axios.put('/options/'+ data.id, data)
        }
    },
}

export default optionActions
