import axios from '@/libs/axios'

const reservationActions = {
    fetchReservations({ commit, dispatch }, searchParams) {
        return axios.get('/reservations', { params: searchParams })
    },
    fetchReservationDashboard({ commit, dispatch }, searchParams) {
        return axios.get('/reservations/dashboard', { params: searchParams })
    },
    fetchReservationStatistics({ commit, dispatch }, data) {
        return axios.get('/reservations/statistics?referenceYear='+data.referenceYear)
    },
    fetchPricingOptions() {
        return axios.get('/reservations/options');
    },
    setReservableStatus({ commit, dispatch }, data){
        return axios.post('reservations/dashboard/setReservableStatus', data)
    },
    setReferenceYear({ commit, dispatch }, data){
        return axios.post('reservations/dashboard/setReferenceYear', data)
    },
    fetchReservation({ commit, dispatch }, id) {
        return axios.get(`/reservations/${id}`)
    },
    printReservation({ commit, dispatch }, id) {
        return axios.post(`/reservations/${id}/print`)
    },
    downloadOverlappingReservations() {
        return axios.post(`/reservations/overlapping`, [], {responseType: 'blob'})
    },
    storeReservation({ commit, dispatch }, data) {
        return axios.post('/reservations', data)
    },
    updateReservation({ commit, dispatch }, data) {
        return axios.put(`/reservations/${data.id}`, data)
    },
    setReservationStatus({ commit, dispatch }, data) {
        return axios.post(`/reservations/${data.id}/setStatus/${data.status}`)
    },
    calculateCount({commit, dispatch}, data) {
        return axios.post('/reservations/calculateCount', data);
    },
    calculateCost({commit, dispatch}, data) {
        return axios.post('/reservations/calculateCost', data);
    },
    canReserve({commit, dispatch}, data) {
        return axios.post('/reservations/canReserve', data);
    },
}

export default reservationActions
