import axios from '@/libs/axios'

const reservationFishingSpotActions = {
  fetchReservationFishingSpots({commit, dispatch}, searchParams) {
    return axios.get('/reservation/fishing-spots', {params: searchParams})
  },
  fetchFishingSpotList({commit, dispatch}, searchParams) {
    return axios.get('/reservation/fishing-spots/list', {params: searchParams})
  },
  fetchFishingSpotsForTableFilter({dispatch}) {
    return new Promise((resolve, reject) => {
      dispatch('fetchFishingSpotList')
        .then(response => {
          const list = []

          if (response.data.entities) {
            for (const key in response.data.entities) {
              list.push({
                value: response.data.entities[key].id,
                text: response.data.entities[key].name,
              })
            }
          }

          resolve(list)
        }).catch(error => {
        reject(error)
      })
    })
  },
  fetchFishingSpotsForSelect({dispatch}) {
    return new Promise((resolve, reject) => {
      dispatch('fetchFishingSpotList')
        .then(response => {
          const list = []
          if (response.data.entities) {
            for (const key in response.data.entities) {
              list.push({
                value: parseInt(response.data.entities[key].id),
                title: response.data.entities[key].name + ' (' + response.data.entities[key].code + ')',
                minimumFee: response.data.entities[key].minimum_fee,
                minAdult: response.data.entities[key].min_adult,
                minAdultYoungster: response.data.entities[key].min_adult_youngster,
                maxAdultYoungster: response.data.entities[key].max_adult_youngster,
                maxChildAttendant: response.data.entities[key].max_child_attendant,
                canUseGeneralFishingMethod: response.data.entities[key].can_use_general_fishing_method,
                canUseBoat: response.data.entities[key].can_use_boat,
                hasPowerConsumption: response.data.entities[key].has_power_consumption,
                hasParkingFee: response.data.entities[key].has_parking_fee,
                canUseCaravan: response.data.entities[key].can_use_caravan,
              })
            }
          }

          resolve(list)
        }).catch(error => {
        reject(error)
      })
    })
  },
  fetchReservationFishingSpot({commit, dispatch}, id) {
    return axios.get(`/reservation/fishing-spots/${id}`)
  },
  storeReservationFishingSpot({commit, dispatch}, data) {
    return axios.post('/reservation/fishing-spots', data)
  },
  updateReservationFishingSpot({commit, dispatch}, data) {
    return axios.put(`/reservation/fishing-spots/${data.id}`, data)
  },
  deleteReservationFishingSpot({commit, dispatch}, id) {
    return axios.delete(`/reservation/fishing-spots/${id}`)
  },
}

export default reservationFishingSpotActions
