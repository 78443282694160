import axios from '@/libs/axios'

const galleryActions = {
    fetchGalleries({ commit, dispatch }, searchParams) {
        // todo fontos get hívásnál hogy a params object key alatt menjenek a keresőparaméterek
        return axios.get('/galleries', { params: searchParams })
    },
    fetchGalleryList({ commit, dispatch }, searchParams) {
        // todo fontos get hívásnál hogy a params object key alatt menjenek a keresőparaméterek
        return axios.get('/galleries/list', { params: searchParams })
    },
    fetchGalleriesForSelect({ dispatch }) {
        return new Promise((resolve, reject) => {
            dispatch('fetchGalleryList')
              .then(response => {
                  const list = []
                  if (response.data.entities) {
                      for (const key in response.data.entities) {
                          list.push({
                              value: parseInt(response.data.entities[key].id),
                              title: response.data.entities[key].title,
                          })
                      }
                  }

                  resolve(list)
              }).catch(error => {
                reject(error)
            })
        })
    },
    fetchGallery({ commit, dispatch }, id) {
        return axios.get(`/galleries/${id}`)
    },
    storeGallery({ commit, dispatch }, data) {
        return axios.post('/galleries', data)
    },
    updateGallery({ commit, dispatch }, data) {
        return axios.put(`/galleries/${data.id}`, data)
    },
    deleteGallery({ commit, dispatch }, id) {
        return axios.delete(`/galleries/${id}`)
    },
    getToBeApprovedCount() {
        return axios.get('/galleries/count-to-be-approved')
    },
    downloadGallery({commit, dispatch}, data) {
        return axios.post('/galleries/download/' + data.id, [],{ responseType: 'blob' });
    },
}

export default galleryActions
