import axios from '@/libs/axios'

const reservationPricingActions = {
    fetchReservationPricing({ commit, dispatch }, searchParams) {
        return axios.get('/reservation/pricing', { params: searchParams })
    },
    updateReservationPricing({ commit, dispatch }, data) {
        return axios.post('/reservation/pricing', data)
    },
}

export default reservationPricingActions
