export default [
  {
    path: '/partners',
    name: 'partners',
    component: () => import('@/views/pages/partner/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.partners',
      resource: 'partner',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/partners/add',
    name: 'add-partner',
    component: () => import('@/views/pages/partner/Add.vue'),
    meta: {
      pageTitle: 'breadcrumb.partners',
      resource: 'partner',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/partners/modify/:id',
    name: 'modify-partner',
    component: () => import('@/views/pages/partner/Modify.vue'),
    meta: {
      pageTitle: 'breadcrumb.partners',
      resource: 'partner',
      action: 'edit',
      breadcrumb: [
        {
          text: 'breadcrumb.modify',
          active: true,
        },
      ],
    },
  },
]
