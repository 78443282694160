import axios from '@/libs/axios'

const reservationMapActions = {
    fetchReservationMaps({ commit, dispatch }, searchParams) {
        return axios.get('/reservation/maps', { params: searchParams })
    },
    fetchReservationMapMarkers({ commit, dispatch }, data) {
        return axios.get('/reservation/maps/markers', {params: data})
    },
    fetchLatestReservationMap({ commit, dispatch }, id) {
        return axios.get(`/reservation/maps/latest`)
    },
    fetchReservationMap({ commit, dispatch }, id) {
        return axios.get(`/reservation/maps/${id}`)
    },
    storeReservationMap({ commit, dispatch }, data) {
        return axios.post('/reservation/maps', data)
    },
    updateReservationMap({ commit, dispatch }, data) {
        return axios.put(`/reservation/maps/${data.id}`, data)
    },
}

export default reservationMapActions
