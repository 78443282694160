export default {
    // example user-create -> Felhasználó[létrehozás]
    localizePermission(permission) {
        let ret = permission

        const pieces = permission.split('-')

        if (pieces.length && pieces.length === 2) {
            // elvben csak két részből állhat mindig
            const roleName = window.i18n.t('roles.'+pieces[0])
            const roleLabel = roleName.charAt(0).toUpperCase() + roleName.slice(1)
            const permissionName = window.i18n.t('roles.'+pieces[1])
            ret = roleLabel + ' [' + permissionName + ']'
        }

        return ret
    },
}
