export default [
  {
    path: '/baits',
    name: 'baits',
    component: () => import('@/views/pages/record-catch/bait/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.baits',
      resource: 'record_catch',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/baits/add',
    name: 'add-bait',
    component: () => import('@/views/pages/record-catch/bait/Add.vue'),
    meta: {
      pageTitle: 'breadcrumb.baits',
      resource: 'record_catch',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/baits/modify/:id',
    name: 'modify-bait',
    component: () => import('@/views/pages/record-catch/bait/Modify.vue'),
    meta: {
      pageTitle: 'breadcrumb.baits',
      resource: 'record_catch',
      action: 'edit',
      breadcrumb: [
        {
          text: 'breadcrumb.modify',
          active: true,
        },
      ],
    },
  },
]
