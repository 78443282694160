export default [
  {
    path: '/reservation/pricing/base-variable-fees',
    name: 'reservation-pricing-base-variable-fees',
    component: () => import('@/views/pages/reservation/pricing/base-variable-fee/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.base-variable-fees',
      resource: 'reservation',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reservation/pricing/base-variable-fees/add',
    name: 'add-reservation-pricing-base-variable-fee',
    component: () => import('@/views/pages/reservation/pricing/base-variable-fee/Add.vue'),
    meta: {
      pageTitle: 'breadcrumb.base-variable-fees',
      resource: 'reservation',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reservation/pricing/base-variable-fees/modify/:id',
    name: 'modify-reservation-pricing-base-variable-fee',
    component: () => import('@/views/pages/reservation/pricing/base-variable-fee/Modify.vue'),
    meta: {
      pageTitle: 'breadcrumb.base-variable-fees',
      resource: 'reservation',
      action: 'edit',
      breadcrumb: [
        {
          text: 'breadcrumb.modify',
          active: true,
        },
      ],
    },
  },
]
