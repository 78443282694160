export default [
  {
    path: '/reservation/pricing-types',
    name: 'reservation-pricing-types',
    component: () => import('@/views/pages/reservation/pricing-type/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.pricing-types',
      resource: 'reservation',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reservation/pricing-types/add',
    name: 'add-reservation-pricing-type',
    component: () => import('@/views/pages/reservation/pricing-type/Add.vue'),
    meta: {
      pageTitle: 'breadcrumb.pricing-types',
      resource: 'reservation',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reservation/pricing-types/modify/:id',
    name: 'modify-reservation-pricing-type',
    component: () => import('@/views/pages/reservation/pricing-type/Modify.vue'),
    meta: {
      pageTitle: 'breadcrumb.pricing-types',
      resource: 'reservation',
      action: 'edit',
      breadcrumb: [
        {
          text: 'breadcrumb.modify',
          active: true,
        },
      ],
    },
  },
]
