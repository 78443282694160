export default [
  {
    path: '/tags',
    name: 'tags',
    component: () => import('@/views/pages/tag/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.tags',
      resource: 'tag',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tags/add',
    name: 'add-tag',
    component: () => import('@/views/pages/tag/Add.vue'),
    meta: {
      pageTitle: 'breadcrumb.tags',
      resource: 'tag',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tags/modify/:id',
    name: 'modify-tag',
    component: () => import('@/views/pages/tag/Modify.vue'),
    meta: {
      pageTitle: 'breadcrumb.tags',
      resource: 'tag',
      action: 'edit',
      breadcrumb: [
        {
          text: 'breadcrumb.modify',
          active: true,
        },
      ],
    },
  },
]
